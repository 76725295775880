.prediction-error-container {
  min-height: 100vh !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .prediction-error-icon {
    max-width: 107px;
    max-height: 97px;
    margin-bottom: 25px;
  }

  .prediction-error-sub-title {
    max-width: 330px;
    color: #fff;
    font-size: 14px;
    text-align: center;
    font-weight: 500;
    margin-bottom: 25px;
  }

  .prediction-error-reason {
    display: flex;
    flex-direction: column;
    gap: 15px;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align-last: left;
    max-width: 275px;
    padding: 0;
    margin-bottom: 7vh;

    li {
      position: relative;
    }

    & li::before {
      content: '';
      position: absolute;
      background-color: #ffffff80;
      border-radius: 50%;
      width: 10px;
      height: 10px;
      left: -20px;
      top: 3px;
    }
  }

  .prediction-error-footer-text {
    max-width: 295px;
    text-align: center;
    color: rgba($color: #fff, $alpha: 0.5);
    line-height: 18px;
    font-size: 12px;
    font-weight: 400;
  }
}

.prediction-error-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}