.is-large-button {
  text-transform: uppercase;
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  padding: 11px 20px;
  background:var(--primary-color);
  border: 1px solid var(--primary-color);
  border-radius: 16px;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  box-shadow: none;

    &:hover:hover:not(:disabled):not(.active) {
        background: #8DB120;
        border: 1px solid #8DB120;
        box-shadow: none;
        color: #fff;
        text-decoration: none;
        cursor: pointer;
        transition: none;
    }

    &:disabled {
      opacity: 0.35;
    }
}

.install-firmware-btn,
.documentation-wrapper,
.export-button,
.additional-filters__container {
  .is-large-button{
    background: transparent;
    border-color: #fff;

    &:hover{
      background: #fff !important;
      color: #000000 !important;
      border-color: #fff !important;
    }
  }
}
