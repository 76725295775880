@import "./source";

.overview {
  display: flex;
  max-width: 2600px;
  max-height: 2000px;
  flex-direction: column;
  height: 100%;
  padding: 0 10px 10px 10px;

  @media (min-width: 1600px) {
    padding: 0 20px 15px 20px;
  }

  .subheader-overview {
    display: flex;
    align-items: center;
    margin: 0;
    height: 40px;

    @media (min-width: 1534px) {
      height: 60px;
    }
  }
}

.overview-wrapper {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(1, 2fr);
  gap: 15px 25px;

  @media (min-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }

  .overview-left-part {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
    height: 100%;
  }

  .overview-right-part {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px 25px;
  }
}

.overview-left-part {
  .vertical-line {
    width: 1px;
    background-color: rgba(255, 255, 255, 0.10);
    height: 75%;
  }

  .production-statistics {
    border: 1px solid #9BC321;
  box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.10), 0px 0px 7px 0px rgba(151, 191, 30, 0.25), 0px 0px 11.7px 0px #97BF1E;
    align-items: center;
    margin: 0;
    line-height: 2;
    border-radius: 16px;
    background-color: $bg-color;
    display: flex;
    width: 100%;

    .today,
    .last-7-days,
    .year {
      padding: 20px 0;
      width: 33%;
    }

    .today {
      color: $today-color;
    }

    .last-7-days {
      color: $last-7-days-color;
    }

    .year {
      color: $year-color;
    }

    .title {
      color: $title-light-color;
      font-weight: 400;
      text-align: center;
      font-size: 25px;
    }

    .value {
      line-height: 1;
      font-weight: 700;
      text-align: center;
      word-spacing: 3px;
      font-size: 40px;
      @include adaptive-value(40, 60);


      span {
        font-weight: 300;
        text-align: center;
        word-spacing: 3px;
        @include adaptive-value(15, 40);
      }
    }
  }

  .plants-data {
    border: 1px solid #9BC321;
  box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.10), 0px 0px 7px 0px rgba(151, 191, 30, 0.25), 0px 0px 11.7px 0px #97BF1E;
    margin: 0;
    padding: 10px 0;
    align-items: center;
    background-color: $bg-color;
    border-radius: 16px;
    display: flex;
    width: 100%;
    justify-content: space-evenly;

    .title {
      color: $title-light-color;
      font-weight: 300;
      text-align: center;
      font-size: 21px;
    }

    .value {
      line-height: 1;
      font-weight: 700;
      color: $value-light-color;
      text-align: center;
      word-spacing: 3px;
      font-size: 40px;
      @include adaptive-value(35, 70);
    }
  }
}

.overview-right-part {
  .widgets-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 5px;
  }
}