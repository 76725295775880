@import "tabs";

$titleColor: #8f939b;
$valueColor: #fff;
$countColor: #fff;

.main-info {
  display: flex;
  align-items: center;
  position: relative;

  p,
  textarea,
  li,
  h4,
  ul {
    border: none;
    margin: 0;
    padding: 0;
  }

  textarea {
    color: $valueColor;
    background-color: transparent;
    height: 25px;
    scrollbar-width: thin;
    scrollbar-color: $valueColor transparent;
  }

  label {
    margin-bottom: 0;
  }

  .user-info-link {
    position: absolute;
    top: 5px;
    right: 5px;
  }

  .main-content {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    row-gap: 15px;
    justify-content: space-between;
  }

  .list {
    align-self: start;
    color: $valueColor;
    letter-spacing: 0.48px;
    font-weight: normal;
    font-size: 16px;

    &.grid {
      display: grid;
      grid-template-columns: auto auto;
      align-items: baseline;
      column-gap: 10px;
      row-gap: 5px;
      grid-template-rows: auto auto;
      align-content: start;

      p {
        text-transform: capitalize;
      }
    }

    &.flex {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
  }

  .list-item {
    min-width: max-content;
  }

  .list-item-title {
    font-size: 16px;
    color: $titleColor;
    text-transform: none;
    line-height: 1.4;
  }

  .main-title {
    color: $valueColor;
    margin-bottom: 15px;
  }

  .general-tab-item-title {
    color: $valueColor;
  }

  .api-key-item {
    background-color: rgba(255, 255, 255, 0.05);
    border-radius: 4px;
    padding: 12px 24px;
  }

  .step-title {
    font-size: 14px;
  }

  &.doc-section,
  &.share-profile {
    flex-direction: column;
    align-items: flex-start;
  }

  .count {
    font-weight: 600;
    color: $countColor;
  }
}

.user-settings {
  margin-bottom: 30px;

  .main-info-tab {
    display: grid;
    gap: 12px 20px;
    margin-bottom: 30px;
    grid-template-areas:
      "common-info"
      "doc-section"
      "share-profile";

    @media (min-width: 1240px) {
      gap: 15px 30px;
      grid-template-areas:
        "common-info common-info"
        "doc-section share-profile";

      .grid {
        column-gap: 10px;
      }
    }

    @media (min-width: 1600px) {
      gap: 20px 40px;
      grid-template-areas:
        "common-info doc-section"
        "common-info share-profile";

      .grid {
        column-gap: 20px;
      }
    }
  }

  .common-info {
    grid-area: common-info;
  }

  .doc-section {
    grid-area: doc-section;
  }

  .share-profile {
    grid-area: share-profile;
  }
}

.connected-auth-check-icon {
  color: #fff !important;
}