.invited-users-wrapper {
  .invited-users-header-container {
    position: absolute;
    right: 15px;
    top: -50px;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
