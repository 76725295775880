$primary: var(--primary-color);
$wrapper-color: #40424d;
$bg-color: #292F32;

.user-settings {
  background-color: $bg-color;

  .MuiTabs-root {
    color: #fff;
  }

  .MuiButtonBase-root {
    opacity: 1;
    font-size: 16px;
  }

  .MuiTabs-flexContainer {
    border-bottom: 1px solid $wrapper-color;
  }

  .Mui-selected {
    color: $primary;
  }

  .MuiTabs-indicator {
    background-color: $primary;
    height: 2px;
  }

  .tab-wrapper {
    border: 1px solid #40424d;
    border-radius: 7px;
  }

  .drop-down-list-reboot {
    .drop-down-list-reboot-btn {
      text-transform: uppercase;
      font-weight: 400;
      padding: 5px 10px !important;
      
    }

    .MuiList-root.MuiList-padding {
      li {
        padding: 0 10px;
        font-size: 14px;
      }
    }
  }
}

.software-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: max(15%, 10px);
}
