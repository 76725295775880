@import "../../styles/source";

.circle-chart-wrapper {
  overflow: hidden;
  border: 1px solid #9BC321;
  box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.10), 0px 0px 7px 0px rgba(151, 191, 30, 0.25), 0px 0px 11.7px 0px #97BF1E;
  padding: 10px;
  min-height: max(40vh, 400px);
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: $bg-color;
  border-radius: 16px;
  gap: 10px;
  justify-content: center;

  .title {
    font-weight: 400;
    color: $title-light-color;
    text-align: center;
    white-space: nowrap;

    @include adaptive-value(15, 27);
  }

  .chart-info {
    width: min-content;
    display: flex;
    justify-content: space-between;
  }
}