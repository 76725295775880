.m-grid__item.m-grid__item--fluid.thank-you-cont {
  color: #fff;
  text-align: center;

  h1 {
    margin-bottom: 30px;
    font-size: 30px;
    font-weight: 600;
    letter-spacing: 0.9px;
  }

  p {
    font-size: 18px;
    font-weight: 400;
  }

  .mail-links {
    margin-top: 47px;

    button,
    a {
      font-weight: 600;
      background-color: transparent;
      border: none;
      color: #fff;
      cursor: pointer;
      text-transform: uppercase;

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  }
}

.thank_you_email:hover {
  text-decoration: underline;
}

.tooltip-for-signup {
  position: absolute;
  right: 0;
  top: 16px;
  font-size: 20px;
  z-index: 155;
}

.qr-code-tooltip {
  font-size: 20px;
  text-align: left;

  .la.la-info-circle {
    margin-right: 0;
    margin-left: 1px;
  }
}

.thank_you_email {
  color: #68b0ee;
  cursor: pointer;
}

.display-none {
  display: none !important;
}

form.m-login__form.m-form {
  position: relative;

  div.m-login__form-action {
    position: relative
  }

  a.qrscan {
    i.la.la-qrcode {
      font-size: 30px;
    }
  }
}