.support-contracts-container {
  margin-top: 30px;
}

.support-contracts-container label {
  display: flex;
  align-items: center;
  line-height: 19px;
}

.support-contracts {
  font-size: 16px !important;
  font-weight: 500;
  color: #8f939b !important;
}

.release-group {
  color: #fff !important;
}

.support-contracts-info-text {
  display: block;
  padding-left: 30px;
  margin-bottom: 10px !important;
  color: #8f939b;
  font-size: 10px;
}

.support-contracts-margin>div>label {
  margin: 0 !important;
}

.support-contracts-container label.support-contracts span {
  background-color: transparent;
  border:1px solid rgba(255, 255, 255, 0.5);
}

.support-contracts input[type="checkbox"]:checked+span {
  background-color: var(--warning);
}

.support-contracts input[type="checkbox"]:disabled+span {
  background-color: #40424d !important;
  border: 1px solid #40424d !important;
}

.support-contracts-container label.support-contracts span::after {
  border-color: #fff !important;
}

.software-update-btn {
  margin-left: 12px !important;
}

.software-update-btn.show-loader {
  padding-left: 0 !important;
  position: relative;
  display: flex !important;
  max-height: 30px;

  span:first-child {
    transform: scale(0.27);
    position: absolute;
    left: 7px !important;
    top: -20px !important;
  }
}

.export-button {
  gap: 20px;

  .headset-icon {
    margin-right: 5px;
  }
}

.support-btn-container {
  color: #494b4e;
  font-size: 12px;
  font-weight: 400;
  padding: 0;
  line-height: 20px;
}

.support-btn-tooltip {
  background: #fff !important;
  max-width: 370px;
}

.support-btn-popper {
  border-radius: 6px;
}

.support-btn {
  text-transform: uppercase;
  display: flex;
  align-items: center;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  padding: 10px 16px;
  background: transparent;
  border: 1px solid #fff;
  border-radius: 16px;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  box-shadow: none;

  &:hover:hover:not(:disabled):not(.active) {
    background: #fff;
    border: 1px solid #fff;
    box-shadow: none;
    color: #000;
    text-decoration: none;
    cursor: pointer;
    transition: none;
  }
}

.dashboard-btn {
  margin-top: 0;
  float: right;
  line-height: 1;
  margin-bottom: 27px;
  text-transform: capitalize;
}

a.btn.m-btn--pill.m-btn--air.btn-secondary.btn-table-inst.dashboard-btn-disabled {
  cursor: default !important;
  pointer-events: none;
  border-color: #9699a2 !important;
  color: #9699a2 !important;
}

.tabs-panel {
  color: #fff;
}