.upgradeToUnlockPopUp,
.deviceLimitReachedPopUp,
.featureNotAvailablePopUp {
  .modal-dialog {
    max-width: 610px;
    width: 100%;
  }

  .modal-content {
    .modal-body {
      color: #fff;
      font-size: 18px;
      font-weight: 400;
      padding: 20px 60px;
      padding-bottom: 20px;
      letter-spacing: 0.54px;
    }

    .modal-header {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 20px;
      padding: 35px 60px;

      .modal-title {
        margin: 0;
      }
    }
  }
}
