.cancel-button {
    display: inline-block;
    transition: none;
    text-align: center;
    background: transparent;
    white-space: nowrap;
    text-transform: uppercase;

    width: auto;
    padding: 11px 20px;
    margin: 0;

    border: 1px solid #fff;
    border-radius: 16px;

    font-size: 16px;
    font-weight: 600;

    line-height: 1;
    color: #fff;
    letter-spacing: 0.64px;
    cursor: pointer;

    -webkit-box-shadow: 0 3px 20px 0 rgba(113, 106, 202, .17);
    -moz-box-shadow: 0 3px 20px 0 rgba(113, 106, 202, .17);
    box-shadow: 0 3px 20px 0 rgba(113, 106, 202, .17);

    &:hover:not(:disabled):not(.active) {
        background-color: #fff;
        color: #000;
    }

    &:focus {
        border-color: #fff;
        background-color: #fff;
        color: #000;
    }
}