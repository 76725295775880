.days-form-control {
  width: 100%;
  min-width: 120px;
  border: none;
  border-bottom: 1px solid rgba(#fff, $alpha: 0.5);

  .MuiSvgIcon-root {
    color: #999;
    font-size: 22px;
    margin-right: -5px;
  }

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
}

.days-select-menu-paper {
  &::-webkit-scrollbar {
    background-color: #202126;
    width: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #8f939b;
  }

  &::-webkit-scrollbar-button {
    padding: 5px;
  }
}

.days-week,
.select-days {
  color: #fff;
  font-size: 14.5px;
  font-weight: 400;
  letter-spacing: 0.48px;
}

.select-days {
  opacity: 0.5;
}

.days-select-menu-root .days-select-menu-paper {
  background-color: #202126 !important;
  color: #fff;
  border-radius: 0 !important;
}

.days-select-menu-paper .days-menu-item {
  padding: 0;
  padding-left: 5px;

  .MuiCheckbox-root {
    color: #40424D;

  }

  .Mui-checked {
    color: var(--primary-color);
  }

  &:hover {
    background-color: inherit !important;
  }
}

.days-menu-item__HR {
  background-color: rgba(0, 0, 0, .5) !important;
  width: 90%;
  margin: 0 auto;
}

.days-form-control .inputCustomWarning {
  color: #f4516c;
  font-weight: 400;
}

.days-menu-item__checkbox {
  padding: 0;
  margin: 0;
  margin-right: 5px;

  .MuiCheckbox-root {
    color: #40424D;
  }

  .Mui-checked {
    color: var(--primary-color);
  }
}