.display-none {
  display: none !important;
}

span.thank_you_email {
  color: #fff;
  cursor: pointer;
}

.thank_you_email-content {
  min-width: 100%;
}

.thank_you_email-container {
  .btn-upd-dashb {
    margin-bottom: 5%;
  }
}