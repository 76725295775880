.additional-filters__container {
  padding: 24px 0;
  background-color: #292F32;
  column-gap: 35px;
  row-gap: 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;

  .multi-select {
    margin: 0 !important;
  }

  .installation-finished-filter__container,
  .support-contracts-filter__container,
  .filter__container {
    position: relative;
    display: flex;
    flex-direction: column;

    &:after {
      content: url('../../assets/images/svg/arrow-down-for-native-select.svg');

      position: absolute;
      top: 32px;
      right: 5px;
    }

    select {
      appearance: none;
      padding: 4px 5px;
    }

    span {
      color: #8F939B;
      font-size: 14px;
    }

    .additional-filters__select {
      cursor: pointer;
      color: #9699A2;
      border: 1px solid #3F494D;
      background-color: #3F494D;
      width: 200px;
      height: 38px;

      option {
        color: #9699a2;
      }
    }
  }

  .additional-filters__reset-btn {
    color: #212529;
    font-size: 13px;
    font-weight: 300;
    height: 40px;
    line-height: 19.5px;
    padding: 0px;
    margin-left: auto;

    &.disable-button {
      button {
        border: 1px solid #9699a2 !important;
        color: #9699a2 !important;
      }
    }
  }
}